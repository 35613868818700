.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
  // Default
  background: $light-background-header;

  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
  }

  @media (prefers-color-scheme: light) {
    background: $light-background-header;
  }

  [data-theme=dark] & {
    background: $dark-background-header;
  }

  [data-theme=light] & {
    background: $light-background-header;
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 880px;
    max-width: 100%;
  }

  #search {
    width: calc(min(300px, calc(100vw - 36px)));
    margin-left: 18px;

    @media #{$media-size-phone} {
      margin-left: 0;
      margin-top: 6px;
    }
  }
}

.pagefind-ui__drawer {
  right: 0;
  width: calc(min(500px, calc(100vw - 36px)));
  position: absolute;
  background-color: $light-background-header;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3);
}

.pagefind-ui__search-input {
  color: $light-color;
}
[data-theme="dark"] .pagefind-ui__search-input {
  color: $dark-color;
}

[data-theme="dark"] .pagefind-ui__drawer {
  background-color: $dark-background-header;
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
}

.theme-toggler {
  fill: currentColor;
}

.not-selectable {
  user-select: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
}